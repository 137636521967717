import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { setLanguage } from "../redux/actions/languageActions"
import PropTypes from "prop-types"
import { connect } from "react-redux"
const locales = require("../i18n/constants")

/**
 *
 * General 404 page
 *
 * TODO: Add navigation to somewhere?
 *
 * @param {Object} setLanguage redux function for handling language state
 * @param {Object} data Translations
 */
const NotFoundPage = ({
  setLanguage,
  data: {
    prismic: { allKeywordss, allFooters },
  },
}) => {
  setLanguage(locales["ca"])

  return (
    <Layout
      showCountry={false}
      hidePicker={true}
      keywords={allKeywordss}
      footer={allFooters}
      navigation={
        <div style={{ textAlign: "center", margin: "5rem auto" }}>
          <h1>NOT FOUND</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>
      }
    >
      <SEO title="Home" />
    </Layout>
  )
}
NotFoundPage.propTypes = {
  setLanguage: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

const mapDispatchToProps = { setLanguage }

export default connect(null, mapDispatchToProps)(NotFoundPage)
export const pageQuery = graphql`
  query {
    prismic {
      allKeywordss(lang: "en-us") {
        edges {
          node {
            glossary
            news
            aboutus
            integritypolicy
            privacypolicy
            disclaimer
            resources
            contact
            about
          }
        }
      }
      allFooters(lang: "en-us") {
        edges {
          node {
            about
            aboutus
            resources
            news
            dictionary
            legal
            disclaimer
            privacypolicy
            whatwedo
            whatwedotext
            responsiblegaming
            responsiblegamingtext
            contact
            contacttext
            bottomlinks
            _linkType
          }
        }
      }
    }
  }
`
